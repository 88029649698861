import React, { useState, useEffect, useContext } from "react";
import logo from "../../assets/images/trip_store_logo-02.png";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { PrimaryButton } from "../../../utils/Buttons";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import SearchIcon from "../../assets/images/search_icon.svg";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import PhoneOutlinedIcon from "@material-ui/icons/PhoneOutlined";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Tracker } from "../../../tracking/tracker";
import { callButtonClickedEvent } from "../../../tracking/events/callButtonClickedEvent";
import ModalComponent from "../ModalComponent/ModalComponent";
import LoginUser from "../Auth/Login/LoginUser";
import WhatsAppIconMobile from "../../assets/images/whatsapp (1).png";
import ChooseUs from "../../assets/images/help.png";
import AboutUs from "../../assets/images/i.png";
import Customize from "../../assets/images/repairing-service.png";
import logout from "../../assets/images/logout.png";
import drawerLogo from "../../assets/images/drawerLogo.png";
import ProfileIcon from "../../../utils/ProfileIcon";
import ProfileDropdown from "../ProfileDropdown/ProfileDropdown";
import { UserContext } from "../hooks/UserContext";
import "./mobilenavbar.css";
import {
  deleteUser,
  homeSearchPlacesList,
  logoutUser,
} from "../Services/services";
import { whatsappButtonClickedEvent } from "../../../tracking/events/whatsappButtonClickedEvent";
import { loginButtonClickedEvent } from "../../../tracking/events/loginButtonClickedEvent";
import { callButtonClickedWithOriginEvent } from "../../../tracking/events/callButtonClickedWithOriginEvent";
import { whatsappMessageUtil } from "../../../utils/whatsappUtil";
import CustomizeForm from "../CustomizeForm/CustomizeForm";
import FormModal from "../FormModal/FormModal";
import RequestCallForm from "../RequestCall/RequestCallForm";
import { logoutClickEvent } from "../../../tracking/events/logoutClickEvent";
import { loginEndEvent } from "../../../tracking/events/loginEndEvent";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SearchBar from "../SearchComponent/SearchBar";
import MobileSearchWrapper from "../MobileSearchWrapper";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
});

const MobileNavBar = ({ onVisibilityChange, shouldHideOnScroll, backgroundColor }) => {
  const { userInfo } = useContext(UserContext);
  const [isCustomizedButtonClicked, setIsCustomizedButtonClicked] =
    useState("");
  const isLoggedIn = localStorage.getItem("access_token");
  const classes = useStyles();
  const userFirstName = userInfo?.name.split(" ")[0];
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleCustomizeClick = () => {
    handleOpenCustomizeModal();
  };
  const history = useHistory();
  const location = useLocation();
  const handleLogoClick = () => {
    history.push("/");
  };
  const list = (anchor) => (
    <>
      <div
        style={{ width: "250px", height: "85%" }}
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <div className="mobile-menu-header">
          <div className="mobile-menu-header-content">
            <div className="mobile-drawer-cross-button">
              {/* <img src={drawerLogo} height="100%" width={120} alt="img" /> */}
              <CloseOutlinedIcon
                style={{
                  color: "grey",
                  fontSize: "24px",
                  backgroundColor: "var(--primary-background-color)",
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              />
            </div>
            {isLoggedIn ? (
              <div className="single-navigation-div-mobile-loggedin">
                <div style={{ marginLeft: "10px" }}>
                  {" "}
                  <ProfileDropdown />
                </div>

                <span className="naviation-text-heading-mobile-loggedin">
                  Hi, {userInfo?.name}
                </span>
              </div>
            ) : (
              <div
                className="single-navigation-div-mobile"
                onClick={() => {
                  handleOpenLoginModal();
                }}
              >
                <AccountCircleIcon
                  style={{
                    color: "var(--primary-background-color)",
                    fontSize: "50px",
                  }}
                />
                <span className="naviation-text-heading-mobile">Login</span>
              </div>
            )}
          </div>
          <div className="mobile-navigation-container">
            <div
              className="drawer-items-list"
              onClick={() => {
                handleCustomizeClick();
              }}
            >
              <img
                src={Customize}
                alt="customize-image"
                width="20px"
                height="20px"
              />{" "}
              <p className="drawer-list-items-text">Customize</p>
            </div>
            <div className="drawer-items-list">
              <img
                src={ChooseUs}
                alt="customize-image"
                width="20px"
                height="20px"
              />{" "}
              <a href="/bookingTs" className="drawer-list-items-text">
                Why Choose Us
              </a>
            </div>

            <div className="drawer-items-list">
              <img
                src={AboutUs}
                alt="customize-image"
                width="20px"
                height="20px"
              />{" "}
              <a href="/About" className="drawer-list-items-text">
                About Us
              </a>
            </div>

            {isLoggedIn ? (
              <div
                className="drawer-items-list"
                style={{ borderTop: "1px solid rgb(211, 208, 208)" }}
                onClick={() => {
                  handleLogoutUser();
                }}
              >
                <img src={logout} width="20px" height="20px" alt="logout" />
                <span className="drawer-list-items-text">Logout</span>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    if (window.scrollY === 0 || shouldHideOnScroll === false) {
      setShow(true);
    } else {
      setOpenUserDropDown(false);
      setShow(false);
    }
  };
  useEffect(() => {
    onVisibilityChange(show);
  }, [show, onVisibilityChange]);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  const handleCallButton = () => {
    Tracker.getInstance().trackEvent(new callButtonClickedEvent());
    Tracker.getInstance().trackEvent(
      new callButtonClickedWithOriginEvent("nav_bar")
    );
  };

  const [openLoginModal, setOpenLoginModal] = useState(false);
  const [openCustomizeModal, setOpenCustomizeModal] = useState(false);
  const [openCallRequestModal, setOpenCallRequestModal] = useState(false);
  const [currentView, setCurrentView] = useState("");

  const handleCloseLoginModal = () => {
    // if (!isLoggedIn) {
    //   console.log("the called1")
    //   Tracker.getInstance().trackEvent(new loginEndEvent(currentView));
    // }
    setOpenLoginModal(false);
  };
  const handleCloseCustomizeModal = () => {
    // if (!isLoggedIn) {
    //   Tracker.getInstance().trackEvent(new loginEndEvent(currentView));
    // }
    setOpenCustomizeModal(false);
  };
  const handleOpenCustomizeModal = () => {
    setOpenCustomizeModal(true);
  };
  // const handleOpenRequestCallModal = () => {
  //   setOpenCallRequestModal(true);
  // };
  // const handleCloseRequestModal = () => {
  //   setOpenCallRequestModal(false);
  // };
  const handleOpenLoginModal = () => {
    setIsCustomizedButtonClicked();

    setOpenLoginModal(true);
    Tracker.getInstance().trackEvent(new loginButtonClickedEvent("nav_bar"));
  };

  const openWhatsApp = () => {
    whatsappMessageUtil();
    Tracker.getInstance().trackEvent(new whatsappButtonClickedEvent("nav_bar"));
  };

  const handleLogoutUser = async () => {
    const response = await logoutUser();
    Tracker.getInstance().trackEvent(new logoutClickEvent());
  };
  const handleDeleteUser = async () => {
    if (isLoggedIn) {
      const response = await deleteUser();
    }
  };
  const [openUserDropDown, setOpenUserDropDown] = useState(false);
  const anchorRef = React.useRef(null);
  const handleToggleDropDown = () => {
    setOpenUserDropDown((prevOpen) => !prevOpen);
  };

  const handleCloseDropDown = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpenUserDropDown(false);
  };
  const [placesList, setPlacesList] = useState([]);
  const [openSearchModalNav, setOpenSearchModalNav] = useState(false);

  const getAllplaces = async () => {
    const response = await homeSearchPlacesList();
    if (response.status === 200) {
      setPlacesList(response.data.data);
    }
  };

  useEffect(() => {
    getAllplaces();
  }, []);

  const handleOpenModalNav = (e) => {
    setOpenSearchModalNav(true);
    e.target.blur();
  };

  const handleCloseModalNav = () => {
    // handleClearAllInputFields();
    setOpenSearchModalNav(false);
    // handleBlur()
  };

  return (
    <nav className={`navbar ${show ? "visible" : "hidden"}`} style={{backgroundColor: backgroundColor ? backgroundColor : ""}} >
      {openSearchModalNav && location.pathname === "/" ? null : (
        <SearchBar
          source="nav_bar"
          openSearchModalNav={openSearchModalNav}
          handleOpenModalNav={handleOpenModalNav}
          handleCloseModalNav={handleCloseModalNav}
          destinationsList={placesList.destinations}
          monthsList={placesList.months}
          budgetsList={placesList.price}
        />
      )}

      <FormModal
        openCustomizeModal={openCustomizeModal}
        handleCloseCustomizeModal={handleCloseCustomizeModal}
        children={<CustomizeForm />}
        // setCurrentView={setCurrentView}
        origin="nav_bar"
      />

      <ModalComponent
        openLoginModal={openLoginModal}
        handleCloseLoginModal={handleCloseLoginModal}
        children={<LoginUser />}
        // setCurrentView={setCurrentView}
      />

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
      >
        {list("right")}
      </SwipeableDrawer>

      <div
        style={{ cursor: "pointer" }}
        onClick={handleLogoClick}
        
        // onClick={
        //   process.env.REACT_APP_NODE_ENV === "staging"
        //     ? handleDeleteUser
        //     : undefined
        // }
      >
        {" "}
        <LazyLoadImage
          src={drawerLogo}
          alt="image"
          effect="blur"
          loading="lazy"
          className="page-logo"
          // height="100%"
          // width={120}
        />
      </div>
      <div className="mobile-call-us-wrapper">
        {location.pathname === "/" ? null : (
          <SearchIcon
            className="mobile-whatsapp-call"
            style={{
              width: "28px",
              height: "28px",
              color: "var(--text-secondary-color)",
            }}
            onClick={handleOpenModalNav}
          />
        )}

        <img
          src={WhatsAppIconMobile}
          alt="whatsapp-image"
          className="mobile-whatsapp-call"
          style={{ width: "24px", height: "24px" }}
          onClick={openWhatsApp}
        />
        <a
          onClick={handleCallButton}
          className="mobile-call-us"
          aria-label="contact"
          href="tel:+91-9209200041"
        >
          <PhoneOutlinedIcon style={{ width: "14px", height: "14px" }} />{" "}
        </a>
        <div
          className={`menu-icon ${isOpen ? "open" : ""}`}
          onClick={toggleDrawer("right", true)}
        >
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </div>
      </div>
    </nav>
  );
};

export default MobileNavBar;
