class Price {
	constructor({ amount, currency }) {
		this.amount = amount
		this.currency = currency
	}

	get displayValue() {
		const formatter = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: this.currency,
			minimumFractionDigits: 0,
			maximumFractionDigits: 0,
			useGrouping: true, // Add this line to enable grouping
		})
		const formattedAmount = formatter.format(this.amount)
		return `${formattedAmount}/-`
	}
}

class SaleInfo {
	constructor({ retail_price, list_price, discountPercentage, discountColor }) {
		this.retailPrice = new Price(retail_price)
		this.listPrice = new Price(list_price)
		this.discountPercentage = discountPercentage
		this.discountColor = discountColor
	}

	get shouldShowRetailPrice() {
		return this.discountPercentage > 0
	}
}

module.exports = SaleInfo
