import apiUrl from "../urls/apiUrls";

export const getScreenSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
};
export const LeadSource = {
  ON_PAGE: "on_page",
  CUSTOMISE: "customise",
  PACKAGE_ENQUIRY: "package_enquiry",
};

export const getSavingAmount = (retailPrice, listingPrice) => {
  return parseFloat(retailPrice) - parseFloat(listingPrice);
};

export const capitalizeSentences = (text) => {
  // const sentences = text.match(/[^.!?]+[.!?]*\s*/g);

  if(text){
    const sentences = text?.match(/[^.!?]+[.!?]*\s*/g);

    const capitalizedSentences = sentences.map((sentence) =>
      sentence
        .trim()
        .toLowerCase()
        .replace(/(?:^|\s+)(\w)/g, function (match, group1) {
          return match.replace(group1, group1.toUpperCase());
        })
    );
  
    return capitalizedSentences.join(" ");
  }

};

export const hideTourOprator = (str) => {
  return str
    .split(" ")
    .map((word) => {
      if (word.length <= 1) {
        return word; // If the word is 1 character or less, return it as is
      }

      let visiblePart = word.substring(0, 1); // Get the first character
      let maskedPart = "x".repeat(4); // Create a string of four crosses

      return visiblePart + maskedPart; // Combine the visible and masked parts
    })
    .join(" ");
};

export const convertUrlToApiEndpoint = (pathname) => {
  if (pathname === "" || pathname === "/") {
    const defaultUrl = `${apiUrl.homePageBaseUrl}/page/home`;
    return defaultUrl;
  } else {
    const match = pathname.match(/^\/([a-zA-Z]{1,3})\/(.*)$/);

    if (match) {
      const components = pathname
        .split("/")
        .filter((component) => component !== "");
      const convertedString = components.join("_");

      const dynamiceApiUrl = `${apiUrl.homePageBaseUrl}/page/${convertedString}`;

      return dynamiceApiUrl;
    }
  }

  return null;
};

export const getFullUrlPath = (location) => {
  const fullPath = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;
  return fullPath;
};
