import styled from "styled-components";

export const PrimaryButton = styled.button`
  position: relative;
  overflow: hidden;
  padding: 8px 16px;
  background-image: linear-gradient(
    to left,
    #f47d2b,
    #f59042,
    #f6a15a,
    #f6b272,
    #f7c28c
  );
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transition: all 0.7s ease-out;
  }

  &:hover::before,
  &:focus::before {
    left: 100%;
  }
`;

export const SecondaryButton = styled.button`
  background-color: #fbe134;
  color: black;
  padding: 0.3rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: darken(var(--secondary-color), 10%);
  }
`;
export const TernaryButton = styled.button`
  background-color: #ff6840;
  color: white;
  padding: 0.3rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
`;

export const PrimaryDisabledButton = styled.button`
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  opacity: 0.7; /* Reduced opacity for a lighter, disabled look */
`;
export const PrimarySubmitButton = styled.button`
  position: relative;
  overflow: hidden;
  padding: 8px 16px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
`;
export const PrimaryBorderedButton = styled.button`
  overflow: hidden;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background-color: var(--primary-background-color);
  justify-content: center;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
  cursor: pointer;

`;
export const SecondaryBorderedButton = styled.button`
  overflow: hidden;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  background-color: var(--primary-color);
  justify-content: center;
  color: var(--primary-background-color);
  border-radius: 5px;
  cursor: pointer;
  border:none;
  outline:none;

`;
