import React, { Component, lazy, Suspense } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";
import Skeleton from "react-loading-skeleton";
import { Grid, Container, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { getPackageDetailAction } from "../../store/actions/getPackageDetail.action";
import { getMonthAction } from "../../store/actions/getMonths";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import { getInclusionAction } from "../../store/actions/getInclusion.action";
import { getThemeAction } from "../../store/actions/getTheme.action";
import { getResponseNullAction } from "../../store/actions/packageList.action";
import { getMetaDetailAction } from "../../store/actions/getMeta.action";
import { getListingAction } from "../../store/actions/listing.action";
import "./packagelist.css";

import { Helmet } from "react-helmet";
import { ListingRequest } from "../../models/listing/listingRequest";
import { Tracker } from "../../../tracking/tracker";
import { FilterRequest } from "../../models/filters/filterRequest";
import { ListingPackageViewDetailEvent } from "../../../tracking/events/listing/listingPackageViewDetailEvent";
import { ListingAddToCompareEvent } from "../../../tracking/events/listing/listingAddToCompareEvent";
import { FilterAppliedEvent } from "../../../tracking/events/listing/filterAppliedEvent";
import NewPackageCard from "./packageCard/newPackageCard";
import InfiniteScroll from "react-infinite-scroll-component";
import LoadMoreWidget from "./pagination/loadMoreWidget";
import { ListingPaginationEvent } from "../../../tracking/events/listing/listingPaginationEvent";
import { WhatsappUtil } from "../../../utils/whatsappUtil";
import { useLocation } from "react-router-dom";
import {
  PackageOpenWhatsappEvent,
  WhatsappOpenEventOrigin,
} from "../../../tracking/events/packageOpenWhatsappEvent";
import { TrackingPageName } from "../../../tracking/trackingConstants";
import { NavigationContext } from "../../navigation/navigationContext";
import DiscountBarComponent from "../DiscountBarComponent/DiscountBarComponent";
import SnackbarComponent, { SnackbarStyle } from "../Snackbar/Snackbar";
import { getFullUrlPath } from "../../../utils/utility";
import Compare from "./PackageCards/Compare/compare";

const Navbar = lazy(() => import("./Navbar/navbar"));
const Footer = lazy(() => import("../footer/footer"));
const PackageNotfound = lazy(() => import("./packageNotFound"));

// const Compare = lazy(() => import("./PackageCards/Compare/compare"));
const style = (theme) => ({
  compareBox: {
    [theme.breakpoints.down("sm")]: {},
  },
});
class PackagesList extends Component {
  state = {
    compareData: JSON.parse(localStorage.getItem("compare")) || [],
    idArray: JSON.parse(localStorage.getItem("packageId")) || [],
    paramId: [],
    monthId: JSON.parse(localStorage.getItem("monthId")) || [],
    destId: JSON.parse(localStorage.getItem("destId")) || [],
    themeId: [],
    seasonId: JSON.parse(localStorage.getItem("seasonId")),
    inclusionId: JSON.parse(localStorage.getItem("inclusionId")) || [],
    popularPackage: localStorage.getItem("popularPackage")
      ? JSON.parse(localStorage.getItem("popularPackage"))
      : null,
    isloading: false,
    scroll: "",
    filterRequest: new FilterRequest([]),
    packageList: [],
    nextPage: 1,
    totalCount: 0,
    showOperator: true,
    open: false,
    openSnackBar: false,
    openAddAnotherMessageSnackBar: false,
  };
  location = window.location;

  static contextType = NavigationContext;
  isLoggedIn = localStorage.getItem("access_token");
  setupOperatorVisibility = () => {
    let url = window.location.search;
    let urlParams = new URLSearchParams(url);

    if (urlParams.has("show_operator")) {
      const value = urlParams.get("show_operator");
      this.setState({ showOperator: !(value === "false" || value === "0") });
    }
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  buildFilterRequestFromUrl = () => {
    let url = window.location.search;
    let urlParams = new URLSearchParams(url);
    let filterRequest = FilterRequest.fromURLSearchParams(urlParams);
    this.setState({
      filterRequest: filterRequest,
    });

    return filterRequest;
  };

  buildListingRequest = (filterRequest) => {
    let url = window.location.search;
    const user_query_param = "query";
    const all_query_param = "all";
    let urlParams = new URLSearchParams(url);

    let query = urlParams.get(user_query_param);
    let fetchAll = urlParams.get(all_query_param);

    let requestData = new ListingRequest(
      filterRequest.filters,
      query,
      fetchAll,
      this.state.nextPage
    );
    return requestData;
  };

  performListingRequest = (filterRequest) => {
    let requestParameters = this.buildListingRequest(filterRequest);
    this.props.getListingAction(requestParameters);
  };

  fetchData = () => {
    let nextPage = this.state.nextPage;

    let filterRequest = this.buildFilterRequestFromUrl();
    this.performListingRequest(filterRequest);
    this.performPaginationTracking(nextPage);
  };

  componentDidMount = () => {
    let filterRequest = this.buildFilterRequestFromUrl();
    this.performPageLevelTracking(filterRequest);
    this.setupOperatorVisibility();

    this.setState({
      isloading: true,
    });

    this.fetchData();

    // Get Metadata
    this.props.getMetaDetailAction({ params: { page: "list" } });

    window.addEventListener("scroll", this.handleScroll);
    window.scrollTo(0, 0);
  };

  performPaginationTracking = (nextPage) => {
    Tracker.getInstance().trackEvent(new ListingPaginationEvent(nextPage));
  };

  performPageLevelTracking = (filterRequest) => {
    this.context.setBrowsingPage(TrackingPageName.PackageList);

    filterRequest.filters.forEach((filter) => {
      Tracker.getInstance().trackEvent(
        new FilterAppliedEvent(filter.type, filter.values)
      );
    });
  };

  handleScroll = () => {
    this.setState({
      scroll: window.scrollY,
    });
  };

  onCompare = (packageObject, index) => {
    const compareDataValue = {
      id: packageObject.id,
      name: packageObject.name,
      price: packageObject.saleInfo.listPrice.amount,
      duration: packageObject.itineraryInfo.durationValue,
      slug: packageObject.slug,
    };
    if (window.innerWidth <= 768 && this.state.idArray.length === 0) {
      this.handleOpenAddAnotherMessageSnackbar();
    }

    if (window.innerWidth <= 768 && this.state.idArray.length === 1) {
      this.handleClickOpen();
    }
    if (window.innerWidth <= 768 && this.state.idArray.length >= 2) {
      this.handleOpenSnackbar();
      return;
    }

    this.setState(
      {
        compareData: this.state.compareData.concat(compareDataValue),
        idArray: this.state.idArray.concat(packageObject.id),
        paramId: this.state.paramId.concat(packageObject.id),
      },
      () => {
        localStorage.setItem("compare", JSON.stringify(this.state.compareData));
        localStorage.setItem("packageId", JSON.stringify(this.state.idArray));
      }
    );
    Tracker.getInstance().trackEvent(
      new ListingAddToCompareEvent(
        packageObject.package_id,
        packageObject.package_name,
        index + 1,
        packageObject.package_slug,
        packageObject.package_price
      )
    );
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  getFullUrlPath = () => {
    const { location } = this.props;
    // return `${location.pathname}${location.search}${location.hash}`;
    return getFullUrlPath(location);
  };

  onViewDetailClick = (packageObject, index) => {
    localStorage.setItem("popularPackage", JSON.stringify(packageObject.slug));
    sessionStorage.setItem("previousPage", this.getFullUrlPath());
    Tracker.getInstance().trackEvent(
      new ListingPackageViewDetailEvent(
        packageObject.id,
        packageObject.name,
        index + 1,
        packageObject.slug,
        packageObject.saleInfo.listPrice.amount
      )
    );
  };

  onWhatsAppClick = (packageObject, index) => {
    let protocol = window.location.protocol;
    let base = window.location.hostname;
    let port = window.location.port;
    let packageDetailsUrl =
      protocol +
      "//" +
      base +
      (port ? ":" + port : "") +
      "/packagedetails/" +
      packageObject.slug;

    Tracker.getInstance().trackEvent(
      new PackageOpenWhatsappEvent(
        WhatsappOpenEventOrigin.LISTING_PAGE,
        packageObject.slug
      )
    );

    const whatsappUtil = new WhatsappUtil();
    whatsappUtil.openWhatsappForPackage(
      packageObject.name,
      packageObject.saleInfo.listPrice.displayValue,
      packageDetailsUrl
    );
  };

  delete = (id) => {
    const deleteFrmLocal = JSON.parse(localStorage.getItem("compare"));
    const afterdelete = deleteFrmLocal.filter((el) => el.id !== id);
    localStorage.setItem("compare", JSON.stringify(afterdelete));
    this.setState({
      compareData: afterdelete,
    });
    const deleteId = JSON.parse(localStorage.getItem("packageId"));
    const iddeleted = deleteId.filter((el) => el !== id);
    localStorage.setItem("packageId", JSON.stringify(iddeleted));
    this.setState({
      idArray: iddeleted,
    });
  };

  componentWillUnmount = () => {
    this.props.getResponseNullAction();
  };

  clearData = () => {
    this.setState({
      scrollY: "",
    });

    window.location.href = `/packages`;
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps._packageList !== this.props._packageList) {
      this.updatePackageList();
    }
  }

  updatePackageList() {
    const packageList = this.props._packageList;
    if (packageList) {
      const updatedPackages = this.props._packageList.listingPackages;
      const nextPage = this.props._packageList.nextPage;
      const totalCount = this.props._packageList.totalCount;
      if (updatedPackages) {
        this.setState((prevState) => ({
          packageList: [...prevState.packageList, ...updatedPackages],
          nextPage: nextPage,
          totalCount: totalCount,
          isloading: false,
        }));
      }
    }
  }

  getNumberOfPackagesString = () => {
    const totalCount = this.state.totalCount;

    if (totalCount === 1) {
      return "1 tour matches your criteria";
    } else {
      return totalCount + " tours match your criteria";
    }
  };
  handleOpenSnackbar = () => {
    this.setState({
      openSnackBar: true,
    });
  };
  handleCloseSnackbar = () => {
    this.setState({
      openSnackBar: false,
    });
  };
  handleOpenAddAnotherMessageSnackbar = () => {
    this.setState({
      openAddAnotherMessageSnackBar: true,
    });
  };
  handleCloseAddAnotherMessageSnackbar = () => {
    this.setState({
      openAddAnotherMessageSnackBar: false,
    });
  };

  render() {
    const { packageList, nextPage, isloading, totalCount } = this.state;
    const { classes, _metaDetail } = this.props;
    const id0 = this.state.compareData?.[0]?.id;
    const id1 = this.state.compareData?.[1]?.id;

    return (
      <React.Fragment>
        <SnackbarComponent
          openSnackBar={this.state.openSnackBar}
          handleCloseSnackbar={this.handleCloseSnackbar}
          handleOpenSnackbar={this.handleOpenSnackbar}
          message="You can only compare up to 2 packages."
          snackBarStyle={SnackbarStyle.ERROR}
        />
        <SnackbarComponent
          openSnackBar={this.state.openAddAnotherMessageSnackBar}
          handleCloseSnackbar={this.handleCloseAddAnotherMessageSnackbar}
          handleOpenSnackbar={this.handleOpenAddAnotherMessageSnackbar}
          message="Select another package to compare."
        />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          maxWidth="md"
          fullWidth
        >
          <DialogContent
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span
                style={{
                  flex: "2",

                  background: "transparent",
                }}
              ></span>{" "}
              <span
                style={{
                  flex: "8",

                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Compare list
              </span>{" "}
              <ClearIcon style={{ flex: "2" }} onClick={this.handleClose} />
            </div>
            <Compare
              handleClose={this.handleClose}
              compareData={this.handleLink}
              click={this.state.compareData}
              id={this.state.idArray}
              paramId={this.state.paramId}
              delete={this.delete.bind(this)}
            />
            {/* </Suspense>
            </Grid> */}
          </DialogContent>
        </Dialog>
        <Helmet>
          <title>
            {_metaDetail.data
              ? _metaDetail.data.data.meta_title
              : "Tripstore - Package List"}
          </title>
          <meta charset="UTF-8" />
          <meta
            name="description"
            content={
              _metaDetail.data ? _metaDetail.data.data.meta_description : ""
            }
          />
          <meta
            name="keywords"
            content={_metaDetail.data ? _metaDetail.data.data.meta_keyword : ""}
          />
          <meta name="author" content="Tripstore" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
        </Helmet>
        <Container
          style={{ paddingTop: window.innerWidth <= 760 ? "" : "10px" }}
        >
          <Suspense
            fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
          >
            <Navbar
              clearData={this.clearData}
              parentPageName={TrackingPageName.PackageList}
              filterRequest={this.state.filterRequest}
            />
          </Suspense>
          {packageList && packageList.length > 0 && isloading === false ? (
            <Typography style={{ marginTop: 132, marginLeft: 8 }}>
              {this.getNumberOfPackagesString()}{" "}
            </Typography>
          ) : (
            ""
          )}
          <Grid>
            {packageList && packageList.length === 0 && isloading === false ? (
              <Grid>
                <Suspense
                  fallback={
                    <h6 style={{ color: "transparent" }}>loading...</h6>
                  }
                >
                  <PackageNotfound
                    month={this.state.monthId}
                    minBudget={this.state.minRange}
                    maxBudget={this.state.maxRange}
                    theme={this.state.themeId}
                    inclusion={this.state.inclusionId}
                    clearData={this.clearData}
                  />
                </Suspense>
              </Grid>
            ) : (
              <Grid md={12} item container justify="space-between" spacing={4}>
                <Grid item md={9} xs={12} container justifyContent="flex-start">
                  {isloading ? (
                    <Skeleton
                      width={
                        window.innerWidth >= 1024
                          ? 900
                          : window.innerWidth * 0.9
                      }
                      height={
                        window.innerWidth >= 1024
                          ? "100%"
                          : window.innerHeight - 140
                      }
                    />
                  ) : (
                    packageList &&
                    packageList.length > 0 && (
                      <InfiniteScroll
                        dataLength={packageList.length}
                        next={this.fetchData}
                        hasMore={nextPage}
                        loader={
                          <Grid
                            md={12}
                            xs={12}
                            sm={12}
                            container
                            item
                            justifyContent="center"
                            alignItems="center"
                            style={{ overflowY: "hidden" }}
                          >
                            <LoadMoreWidget />
                          </Grid>
                        }
                      >
                        {packageList.map((obj, index) => {
                          return (
                            <Grid
                              md={12}
                              xs={12}
                              sm={12}
                              container
                              item
                              key={index}
                              style={{ marginBottom: "20px" }}
                            >
                              <NewPackageCard
                                packageData={obj}
                                index={index}
                                onPackageDetailClick={() => {
                                  this.onViewDetailClick(obj, index);
                                }}
                                onCompareClick={() => {
                                  this.onCompare(obj, index);
                                }}
                                shouldShowCompareButton={
                                  !(
                                    this.state.idArray.includes(obj.id) ||
                                    this.state.idArray.length >= 3
                                  )
                                }
                                shouldShowOperatorName={this.state.showOperator}
                                onWhatsAppClick={() => {
                                  this.onWhatsAppClick(obj, index);
                                }}
                              />

                              {this.isLoggedIn ? null : (
                                <div className="packagelist-discount-bar">
                                  {" "}
                                  <DiscountBarComponent
                                    color="var(--primary-background-color)"
                                    fontStyle=""
                                    fontWeight="bold"
                                    origin="package-list"
                                    packageId={obj.id}
                                  />
                                </div>
                              )}
                            </Grid>
                          );
                        })}
                      </InfiniteScroll>
                    )
                  )}
                </Grid>
                {this.state.compareData.length == 1 ? (
                  <div className="floating-bottom-bar1">
                    Select another package to compare
                  </div>
                ) : this.state.compareData.length > 1 ? (
                  <div className="floating-bottom-bar">
                    <button
                      // href={`/comparelist?packages1=${id0}&packages2=${id1}&packages3=${null}`}
                      onClick={this.handleClickOpen}
                    >
                      Compare Now
                    </button>
                  </div>
                ) : null}
                {window.innerWidth <= 768 ? null : (
                  <Grid md={3} item className={classes.compareBox}>
                    {/* <Suspense
                      fallback={
                        <h6 style={{ color: "transparent" }}>loading...</h6>
                      }
                    > */}
                    <Compare
                      compareData={this.handleLink}
                      click={this.state.compareData}
                      id={this.state.idArray}
                      paramId={this.state.paramId}
                      delete={this.delete.bind(this)}
                    />
                    {/* </Suspense> */}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Container>

        {!isloading ? (
          <Suspense
            fallback={<h6 style={{ color: "transparent" }}>loading...</h6>}
          >
            <Footer />
          </Suspense>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  }
}
const mapStateToProps = ({
  getMonthReducers,
  getPackageDetailReducer,
  getInclusionReducer,
  getThemeReducer,
  postCompareListReducer,
  getMetaDetailReducer,
  listingReducer,
}) => {
  return {
    _months: getMonthReducers,
    _inclusion: getInclusionReducer,
    _theme: getThemeReducer,
    _packageList: listingReducer,
    _comparelist: postCompareListReducer,
    _packageDetails: getPackageDetailReducer,
    _metaDetail: getMetaDetailReducer,
  };
};
export default withRouter(
  connect(mapStateToProps, {
    getMonthAction,
    getInclusionAction,
    getThemeAction,
    getListingAction,
    getPackageDetailAction,
    getResponseNullAction,
    getMetaDetailAction,
  })(withStyles(style)(PackagesList))
);
