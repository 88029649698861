import React, { useEffect } from "react";
import Landing from "../components/landing/landing";
import { Tracker } from "../../tracking/tracker";
import { AppOpenEvent } from "../../tracking/events/appOpenEvent";
import { getTrackingPageName } from "../../tracking/trackingConstants";
import { NavigationProvider } from "../navigation/navigationProvider";
import { UserProvider } from "../components/hooks/UserContext";
import { refreshToken } from "../components/Services/services";

function App() {
  const tracker = new Tracker();
  tracker.configure();

  // Not tracking app load right now as it triggers on refreshes as well
  if (sessionStorage.getItem("isAppLoaded") === null) {
    sessionStorage.setItem("isAppLoaded", true);
    const currentPath = window.location.pathname;
    tracker.trackEvent(new AppOpenEvent(getTrackingPageName(currentPath)));
  }

  const checkAndRefreshToken = async () => {
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      const response = await refreshToken();
      if (response.status >= 200 && response.status < 300) {
        localStorage.setItem("access_token", response.data.data.access_token);
      }
    }
  };

  useEffect(() => {
    checkAndRefreshToken();
  }, []);

  return (
    <UserProvider>
      <NavigationProvider>
        <Landing />
      </NavigationProvider>
    </UserProvider>
  );
}

export default App;
