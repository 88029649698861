import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import withStyles from "@material-ui/core/styles/withStyles";
import { Typography, Grid, Button, Box, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import AddPackage from "./AddPackage";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { Tracker } from "../../../../../tracking/tracker";
import { ListingCompareEvent } from "../../../../../tracking/events/listing/listingCompareEvent";
import { ListingRemoveFromCompareEvent } from "../../../../../tracking/events/listing/listingRemoveFromCompareEvent";
import { getFullUrlPath } from "../../../../../utils/utility";

const style = (theme) => ({
  packageName: {
    textTransform: "capitalize",
  },
});

class Compare extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idArray: JSON.parse(localStorage.getItem("packageId")) || [],
      click: [],
      isLoading: true,
      scroll: "",
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll);
  };

  handleScroll = () => {
    this.setState({
      scroll: window.pageYOffset,
    });
  };
  _handleDelete = (obj) => {
    this.props.delete(obj.id);
    if (this.props.click.length === 0) {
      this.props.handleClose();
    }
    Tracker.getInstance().trackEvent(
      new ListingRemoveFromCompareEvent(obj.id, obj.name, obj.slug, obj.price)
    );
  };
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  getFullUrlPath = () => {
    const { location } = this.props;
    // return `${location.pathname}${location.search}${location.hash}`;
    return getFullUrlPath(location);
  };
  onClick = () => {
    let ids = [];
    let prices = [];
    let names = [];

    this.props.click.forEach((element) => {
      ids.push(element.id);
      prices.push(element.price);
      names.push(element.name);
    });
    sessionStorage.setItem("previousPage", this.getFullUrlPath());
    Tracker.getInstance().trackEvent(
      new ListingCompareEvent(ids, prices, names)
    );
  };

  render() {
    const { classes, click, handleClose } = this.props;


    let id = click.map((obj) => {
      return obj.id;
    });

    let id0 = id[0];
    let id1 = id[1];
    let id2;
    if (id[2]) {
      id2 = id[2];
    } else {
      id2 = null;
    }

    const defaultProps = {
      borderColor: "#e9eaeb",
      background: "#c4c4c4",
      marginTop: "12px",
      m: 1,
      border: 1,
      width: "100%",
      borderRadius: 4,
    };

    return (
      <React.Fragment>
        <Grid
          container
          justifyContent="flex-end"
          style={{
            position: "sticky",
            display: "block",
            top: window.innerWidth <= 768 ? "2%" : "20%",
          }}
        >
          <Box {...defaultProps} style={{ background: "#fff" }}>
            {click != null
              ? click.slice(0, 3).map((obj, index) => (
                  <Grid container justifyContent="center" key={index}>
                    <Box
                      style={{
                        background: "#f3f5f6",
                        // border: " 2px solid #e9eaeb",
                        width: 248,
                        padding: 10,
                        height: "100%",
                        position: "relative",
                       border: window.innerWidth <= 768 ?"1px solid var(--text-secondary-color)": ""
                      }}
                      {...defaultProps}
                    >
                      <Grid
                        md={12}
                        xs={12}
                        item
                        container
                        justifyContent="flex-start"
                        style={{
                          minHeight: window.innerWidth <= 768 ? "100%" : 100,
                        }}
                      >
                        <div>
                          {window.innerWidth <= 1000 ? (
                            <Grid
                              md={1}
                              item
                              container
                              justifyContent="flex-end"
                              
                           
                            >
                              <IconButton
                                onClick={this._handleDelete.bind(this, obj)}
                                style={{ padding: 0 }}
                              >
                                <CloseOutlinedIcon
                                  fontSize="small"
                                  style={{
                                    top: -20,
                                    right: 2,
                                    position: "absolute",
                                    color: "#16222b",
                                    backgroundColor: "white",
                                    borderRadius: 50,
                                  }}
                                />
                              </IconButton>
                            </Grid>
                          ) : null}
                        </div>
                        <Grid md={11} item style={{ marginBottom: 20 }}>
                          <Typography
                            variant="body2"
                            style={{
                              color: "#16222b",
                            }}
                            className={classes.packageName}
                          >
                            {obj.name.toLowerCase()}
                          </Typography>
                          <Typography
                            variant="overline"
                            style={{ color: "#616e78" }}
                          >
                            {obj.duration}
                          </Typography>
                        </Grid>
                        {window.innerWidth <= 768 ? null : (
                          <Grid md={1} item container justifyContent="flex-end" >
                            <IconButton
                              onClick={this._handleDelete.bind(this, obj)}
                              style={{ padding: 0 }}
                            >
                              <CloseOutlinedIcon
                                fontSize="small"
                                style={{
                                  top: 0,
                                  right: 2,
                                  position: "absolute",
                                  color: "#16222b",
                                }}
                              />
                            </IconButton>
                          </Grid>
                        )}
                        <Grid style={{ position: "absolute", bottom: 6 }}>
                          <Typography
                            variant="body1"
                            style={{
                              color: "#0b3954",
                              fontWeight: "500px",
                            }}
                          >
                            Rs.{" "}
                            {Number(obj.price).toLocaleString("en-IN", {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            })}
                            /-
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                ))
              : ""}
            {window.innerWidth <= 768 ? (
              click.length < 1 ? (
                <Grid>
                  <AddPackage />
                  <AddPackage />
                </Grid>
              ) : click.length < 2 ? (
                <Grid>
                  <AddPackage handleClose={this.props.handleClose} />
                </Grid>
              ) : (
                ""
              )
            ) : click.length < 1 ? (
              <Grid>
                <AddPackage />
                <AddPackage />
                <AddPackage />
              </Grid>
            ) : click.length < 2 ? (
              <Grid>
                <AddPackage />
                <AddPackage />
              </Grid>
            ) : click.length < 3 ? (
              <AddPackage />
            ) : (
              ""
            )}

            {click.length < 2 ? (
              <Button
                variant="contained"
                // component={Link}
                disabled
                style={{
                  width: "93%",
                  color: "var(--text-primary-color)",
                  cursor: "no-drop",
                  borderRadius: "4px",
                  backgroundColor: "#e9eaeb",
                  margin: 10,
                  textTransform: "none",
                  // border: "1px solid var(--text-secondary-color)",
                }}
              >
                <Typography style={{ color: "#bbc2c8" }}>Compare</Typography>
              </Button>
            ) : (
              <Button
                variant="contained"
                // target="_blank"
                href={`/comparelist?packages1=${id0}&packages2=${id1}&packages3=${id2}`}
                style={{
                  width: "93%",
                  backgroundColor: "#0b3954",
                  color: "#fff",
                  borderRadius: "4px",
                  margin: 10,
                  textTransform: "none",
                }}
                onClick={this.onClick}
              >
                Compare
              </Button>
            )}
          </Box>
          {this.state.scroll < 350 ? (
            ""
          ) : (
            <Grid
              container
              justifyContent="flex-end"
              style={{
                position: "sticky",
                marginTop: "30%",
                paddingBottom: "5%",
              }}
            >
              <IconButton
                onClick={this.scrollToTop}
                style={{ background: "#ff8362" }}
              >
                <KeyboardArrowUpIcon style={{ color: "#fff" }} />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  return {
    state: state,
    cookies: ownProps.cookies,
  };
};

export default withRouter(
  connect(mapStateToProps)(withStyles(style, { withTheme: true })(Compare))
);
