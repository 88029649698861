import { TrackerPropertyKey, TrackingEventName } from "../../trackingConstants"
import { TrackableEvent } from "../trackableEvent"

export class FilterAppliedEvent extends TrackableEvent {
	constructor(type, values) {
		let properties = {}
		properties[TrackerPropertyKey.FilterType] = type
		properties[TrackerPropertyKey.FilterValues] = values

		super(TrackingEventName.FilterApplied, properties)
	}
}
