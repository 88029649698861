import { Grid, Paper } from "@material-ui/core";
import { withStyles, withTheme } from "@material-ui/core/styles";
import React, { Component } from "react";
import PackageTitle from "./packageTitle";
import ItineraryOverview from "./itineraryOverview";
import CityList from "./cityList";
import InclusionList from "./inclusionList";
import PricingWithActions from "./pricingWithActions";
import PackageCardImage from "./packageCardImage";
import Registration from "../../PackagesList/RequestForPackage/PackageCall";
import { Tracker } from "../../../../tracking/tracker";
import { ListingRequestCallEvent } from "../../../../tracking/events/listing/listingRequestCallEvent";
import { TrackingOrigin } from "../../../../tracking/trackingConstants";
import { ListingRequestCallSubmitEvent } from "../../../../tracking/events/listing/listingRequestCallSubmitEvent";
import DiscountBarComponent from "../../DiscountBarComponent/DiscountBarComponent";

const styles = (theme) => ({
  paper: {
    backgroundColor: "#f8f8f8",
    
    borderRadius:window.innerWidth <= 768? "8px":"",
    boxShadow
    : "rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 8px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;",

  },
  paperLoggedIn: {
    backgroundColor: "#f8f8f8",
    
    borderRadius:window.innerWidth <= 768? "8px":"",

  },
  content: {
    paddingLeft: theme.spacing(2),
  },
  pricingGrid: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
});

class NewPackageCard extends Component {
  state = {
    requestCallOpen: false,
    requestCallPackageId: null,
    pageNumber: 2, // Never changing. Needed for backend call
  };
   isLoggedIn = localStorage.getItem("access_token");
  onRequestCallClick = () => {
    const { packageData, index } = this.props;
    Tracker.getInstance().trackEvent(
      new ListingRequestCallEvent(
        packageData.id,
        packageData.name,
        index ? index + 1 : 0,
        packageData.slug,
        packageData.saleInfo.listPrice.amount
      )
    );
    this.setState({
      requestCallOpen: true,
      requestCallPackageId: packageData.id,
    });
  };

  onRequestCallClose = () => {
    this.setState({ requestCallOpen: false });
  };

  onRequestSubmit = () => {
    const { packageData, index } = this.props;
    Tracker.getInstance().trackEvent(
      new ListingRequestCallSubmitEvent(
        packageData.id,
        packageData.name,
        index ? index + 1 : 0,
        packageData.slug,
        packageData.saleInfo.listPrice.amount
      )
    );
  };

  componentDidMount() {}

  render() {
    const { packageData, classes } = this.props;
    const itineraryInfo = packageData.itineraryInfo;
    const citiesInfo = itineraryInfo && itineraryInfo.cities;
    const inclusions = packageData.inclusions;
    const shouldShowCompareButton = this.props.shouldShowCompareButton ?? false;

    return (
      <Paper className={this.isLoggedIn ? `${ classes.paperLoggedIn}`:`${ classes.paper}`} elevation={2}>
        <Grid container direction="column">
          <Grid container>
            <Grid item xs={12} md={5}>
              <PackageCardImage
                packageData={packageData}
                onCompareClick={this.props.onCompareClick}
                shouldShowCompareButton={shouldShowCompareButton}
                onImageClick={this.props.onPackageDetailClick}
              />
            </Grid>
            <Grid item xs={12} md={7}>
              <div className={classes.content}>
                <Grid container item>
                  <Grid container item>
                    <PackageTitle
                      packageData={packageData}
                      onPackageDetailClick={this.props.onPackageDetailClick}
                      shouldShowOperatorName={
                        this.props.shouldShowOperatorName ?? true
                      }
                    />
                  </Grid>
                  {itineraryInfo && (
                    <Grid container item>
                      {/* Add extra space here */}
                      <ItineraryOverview itineraryInfo={itineraryInfo} />
                    </Grid>
                  )}
                  {itineraryInfo && (
                    <Grid container item style={{ marginTop: "8px" }}>
                      <CityList cities={citiesInfo} />
                    </Grid>
                  )}
                  {inclusions && (
                    <Grid container item style={{ marginTop: "8px" }}>
                      <InclusionList inclusions={inclusions} />
                    </Grid>
                  )}
                  <Grid container item className={classes.pricingGrid}>
                    <PricingWithActions
                      packageData={packageData}
                      onPackageDetailClick={this.props.onPackageDetailClick}
                      onRequestCallClick={this.onRequestCallClick}
                      onWhatsAppClick={this.props.onWhatsAppClick}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Registration
            open={this.state.requestCallOpen}
            id={this.state.requestCallPackageId}
            pageNumber={this.state.pageNumber}
            onClose={this.onRequestCallClose}
            origin={TrackingOrigin.LeadForm.PackageList}
            onRequestSubmit={this.onRequestSubmit}
          />
        </Grid>
      </Paper>
    );
  }
}

export default withStyles(styles, { withTheme })(NewPackageCard);
