import { put, call } from "redux-saga/effects"
import * as types from "../action.types"
import { getFiltersService } from "../services/filter.service"
import { getFiltersActionSuccess } from "../actions/filters.action"

export function* getFiltersSaga(data) {
    try {
        const response = yield call(getFiltersService, data)
        yield put(getFiltersActionSuccess(response.data))
    } catch (error) {
        yield put({ type: types.GET_FILTERS_ERROR, error })
    }
}
